import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { useInView } from "react-intersection-observer";

const ContactForm = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [formData, setFormData] = useState({
    help: "",
    gender: "",
    age: "",
    reason: "",
    motivation: "",
    fullName: "",
    email: "",
    mobile: "",
    country: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const questions = [
    {
      question: "What can I help you with?",
      alternatives: ["Build muscle", "Lose weight", "Healthy lifestyle"],
      key: "help",
    },
    {
      question: "I am?",
      alternatives: ["Man", "Woman", "Other"],
      key: "gender",
    },
    {
      question: "I am?",
      alternatives: ["18-25 years", "25-35 years", "Over 35 years"],
      key: "age",
    },
    {
      question: "Why do you want to work with me?",
      key: "reason",
      maxLength: 500,
    },
    {
      question: "What motivates you to start now?",
      key: "motivation",
      maxLength: 500,
    },
  ];

  const handleAnswer = (answer) => {
    setFormData((prevData) => ({
      ...prevData,
      [questions[questionIndex].key]: answer,
    }));
  };

  const handleNext = () => {
    setQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const areRequiredFieldsFilled = () => {
    const requiredFields = ["fullName", "email", "mobile", "country"];
    return requiredFields.every((field) => formData[field].trim() !== "");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const serviceID = "service_an0f15b";
    const templateID = "template_8p4k217";
    const userID = "9vQRfF7q_5fiy3QWx";

    const templateParams = {
      help: formData.help,
      gender: formData.gender,
      age: formData.age,
      reason: formData.reason,
      motivation: formData.motivation,
      fullName: formData.fullName,
      email: formData.email,
      mobile: formData.mobile,
      country: formData.country,
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
      });
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isPhoneNumberValid = (phoneNumber) => {
    const phonePattern = /^\d+$/;
    return phonePattern.test(phoneNumber);
  };

  const renderQuestion = () => {
    if (formSubmitted) {
      return (
        <div className="text-center z-10">
          <p className="text-lg font-bold mb-2 text-white">
            Thank you, we will be in touch with you soon!
          </p>
        </div>
      );
    }
    if (questionIndex < questions.length) {
      const currentQuestion = questions[questionIndex];
      const isAnswered = formData[currentQuestion.key];

      return (
        <div className="my-4 text-center">
          <p className="text-lg font-bold mb-2 text-white">
            {currentQuestion.question}
          </p>
          {currentQuestion.alternatives && (
            <div className="flex justify-center mb-4">
              {currentQuestion.alternatives.map((alt, index) => (
                <button
                  key={index}
                  className={`px-4 py-2 mr-2 rounded ${
                    formData[currentQuestion.key] === alt
                      ? "bg-gray-300 text-white"
                      : "bg-cyan-500 text-white hover:bg-cyan-600"
                  }`}
                  onClick={() => handleAnswer(alt)}
                >
                  {alt}
                </button>
              ))}
            </div>
          )}
          {currentQuestion.maxLength && (
            <textarea
              id="message"
              rows="4"
              class=" mb-2 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-cyan-500"
              placeholder="Write your thoughts here..."
              onChange={(e) => handleAnswer(e.target.value)}
              value={formData[currentQuestion.key]}
              maxLength={currentQuestion.maxLength}
            />
          )}
          <div className="flex justify-center">
            {questionIndex > 0 && (
              <button
                className="px-4 py-2 mr-2 bg-cyan-600 text-white rounded hover:bg-cyan-500"
                onClick={handlePrevious}
              >
                Previous
              </button>
            )}
            <button
              className={`px-4 py-2 rounded ${
                !isAnswered
                  ? "bg-gray-300 text-gray-900 cursor-not-allowed"
                  : "bg-cyan-500 text-white hover:bg-cyan-600"
              }`}
              onClick={handleNext}
              disabled={!isAnswered}
            >
              Next
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="text-center z-10">
        <p className="text-lg font-bold mb-2 text-white">
          Thank you for your answers!
        </p>
        <p className="mb-2 text-white">Please leave your contact details:</p>
        <div className="flex flex-col items-center">
          <input
            type="text"
            placeholder="Full Name"
            className="p-2 border border-gray-300 rounded mb-2 text-gray-900"
            value={formData.fullName}
            onChange={(e) =>
              setFormData({ ...formData, fullName: e.target.value })
            }
          />
          <input
            type="email"
            placeholder="Email"
            className={`p-2 border border-gray-300 rounded mb-2 text-gray-900 ${
              formData.email.trim() !== "" && !isEmailValid(formData.email)
                ? "border-red-500"
                : ""
            }`}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          {formData.email.trim() !== "" && !isEmailValid(formData.email) && (
            <p className="text-red-500 text-sm">
              Please enter a valid email address.
            </p>
          )}
          <input
            type="text"
            placeholder="Mobile Phone Number"
            className={`p-2 border border-gray-300 rounded mb-2 text-gray-900 ${
              formData.mobile.trim() !== "" &&
              !isPhoneNumberValid(formData.mobile)
                ? "border-red-500"
                : ""
            }`}
            value={formData.mobile}
            onChange={(e) =>
              setFormData({ ...formData, mobile: e.target.value })
            }
          />
          {formData.mobile.trim() !== "" &&
            !isPhoneNumberValid(formData.mobile) && (
              <p className="text-red-500 text-sm">
                Please enter a valid phone number.
              </p>
            )}
          <input
            type="text"
            placeholder="Country"
            className="p-2 border border-gray-300 rounded mb-2 text-gray-900"
            value={formData.country}
            onChange={(e) =>
              setFormData({ ...formData, country: e.target.value })
            }
          />
          <button
            className={`px-4 py-2 bg-cyan-600 text-white rounded ${
              !areRequiredFieldsFilled()
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-cyan-500"
            }`}
            onClick={sendEmail}
            disabled={!areRequiredFieldsFilled()}
          >
            Submit
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className={`rounded-b-xl container mx-auto p-4 min-h-screen flex flex-col justify-center items-center relative  ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
      style={{
        backgroundImage: `url('/assets/NewImageAbdul.JPG')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="rounded-xl absolute top-0 left-0 w-full h-full bg-black opacity-25 z-0"></div>
      <p className="text-4xl lg:text-8xl montserrat lg:mb-8 mb-40 relative z-10 text-white">
        Hassoni Exclusive
      </p>
      <div className="flex flex-col items-center poppins z-10">
        {renderQuestion()}
      </div>
    </div>
  );
};

export default ContactForm;
