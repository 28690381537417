import React from "react";
import FaqsCard from "./faqcards";
const FaqsSection = () => {
  const faqsList = [
    {
      q: "What are the best types of exercises for overall fitness?",
      a: "The best types of exercises for overall fitness include a combination of cardiovascular exercises (e.g., running, cycling), strength training (e.g., weightlifting, bodyweight exercises), flexibility exercises (e.g., yoga, stretching), and balance training (e.g., pilates, tai chi). This well-rounded approach ensures you work different muscle groups, improve endurance, and maintain joint health.",
    },
    {
      q: "How can I stay motivated to stick to my workout routine and healthy eating habits?",
      a: "Staying motivated requires setting realistic goals, finding activities you enjoy, and celebrating small achievements along the way. Additionally, consider finding a workout buddy or seeking support from a fitness community. Tracking your progress and focusing on the positive changes in your body and energy levels can also help you stay committed to your training and nutrition plan.",
    },
    {
      q: "Should I follow a specific diet plan for my workouts, or can I eat normally?",
      a: "Your diet plays a crucial role in supporting your workouts and overall health. While you don't necessarily need to follow a specific diet plan, it's essential to focus on a balanced and nutritious diet. Aim to include a variety of fruits, vegetables, lean proteins, whole grains, and healthy fats in your meals. Proper nutrition will help optimize your performance during workouts, aid in recovery, and enhance your body's ability to build muscle and burn fat.",
    },
    ,
  ];

  return (
    <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8 poppins">
      <div className="space-y-3 text-center">
        <h2 className="text-3xl text-gray-800 font-semibold">
          Frequently Asked Questions
        </h2>
        <p className="text-gray-600 max-w-lg mx-auto text-lg">
          Not finding your questions or answers? feel free to contact us.
        </p>
      </div>
      <div className="mt-14 max-w-2xl mx-auto">
        {faqsList.map((item, idx) => (
          <FaqsCard key={idx} idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
};

export default FaqsSection;

{
  /* <div className="flex items-center mt-4 space-x-4 sm:mt-0">
  <ul class="mt-8 flex justify-center gap-6 sm:mt-0 lg:justify-end">
    <li>
      <a
        href="https://www.facebook.com/Aryamsthlm/"
        target="_blank"
        className="poppins text-md z-10 hover:scale-125 hover:text-cyan-500"
      >
        <BsFacebook className="w-6 h-6" />
      </a>
    </li>

    <li>
      <a
        href="https://www.instagram.com/aryam.se/"
        target="_blank"
        className="poppins text-md z-10 hover:scale-125 hover:text-cyan-500"
      >
        <BsInstagram className="w-6 h-6" />
      </a>
    </li>
  </ul>
</div>; */
}
