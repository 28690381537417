import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const OurOffers = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // useEffect to update the fade state when the element comes into view
  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const plans = [
    {
      name: "HX",
      desc: "With Hassoni Exclusive Coaching, you will have access to my application and the features below!",
      price: "?",
      isMostPop: false,
      features: [
        "💪🏽 Training programs",
        "🥑 Nutrition plans",
        "🎯 Personalized nutritional targets",
        "👀 Effective techniques for managing stress",
        "🎥 Improving flexibility and strength",
        "🎬 Valuable videos (nutrition, training, mindset)",
        "🎬 video library of all exercises",
        "📲Access to my app",
        "🤝🏽 No commitments—cancel anytime",
      ],
    },
    {
      name: "HX - 1:1",
      desc: "Experience enhanced coaching with additional features for better results!",
      price: "??",
      isMostPop: true,
      features: [
        "💪🏽 Training programs",
        "🥑 Nutrition plans",
        "🎯 Personalized nutritional targets",
        "👀 Effective techniques for managing stress",
        "🎥 Improving flexibility and strength",
        "🎬 Valuable videos (nutrition, training, mindset)",
        "🎬 video library of all exercises",
        "📲Access to my app",
        "💬 Daily check-ins",
        "💬 Weekly check-ins",
        "👋🏽 Daily support (24/7)",
      ],
    },
  ];

  const handlePackageChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section
      ref={ref}
      className={`relative py-14 poppins ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
    >
      <div className="max-w-screen-xl mx-auto text-gray-600 sm:px-4 md:px-8">
        <div className="relative max-w-xl mx-auto space-y-3 px-4 sm:text-center">
          <h3 className="text-cyan-600 poppins">Subscription</h3>
          <p className="text-gray-800 text-3xl poppins sm:text-4xl">
            Features that will include in your subscription
          </p>
          <p>
            Let this decision be your best decision. This is where your journey
            starts. Reach your full potential with Hassoni Exclusive.
          </p>
        </div>
        <div className="mt-16 justify-center sm:flex">
          {plans.map((item, idx) => (
            <div
              key={idx}
              className={`relative flex-1 flex items-stretch flex-col mt-6 sm:mt-0 sm:rounded-xl sm:max-w-md ${
                item.isMostPop ? "bg-white shadow-lg sm:border" : ""
              }`}
            >
              <div className="p-4 py-8 space-y-4 border-b md:p-8">
                <span className="text-cyan-600 text-4xl font-bold flex justify-center">
                  {item.name}
                </span>
                <p>{item.desc}</p>
                <button
                  onClick={
                    item.isMostPop
                      ? scrollToTop
                      : () =>
                          window.open(
                            "https://www.trainerize.me/checkout/hassoniexclusive/Abdul.Hassoni?planGUID=b136a1781fbb45a4b08944a6fc22011f",
                            "_blank"
                          )
                  }
                  className="px-3 py-3 rounded-lg w-full text-sm duration-150 text-white bg-cyan-600 hover:bg-cyan-500 active:bg-cyan-700"
                >
                  {item.isMostPop ? "Join now🥇!" : "Join now💪!"}
                </button>
              </div>
              <ul className="p-4 py-8 space-y-3 md:p-8">
                <li className="pb-2 text-gray-800">
                  <p>Features</p>
                </li>
                {item.features.map((featureItem, idx) => (
                  <li key={idx} className="flex items-center gap-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-cyan-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {featureItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurOffers;
