import React, { useState, useEffect } from "react";
import "./styles.css";
import Intro from "./components/intro";
import AboutHE from "./components/aboutHE";
import OurOffers from "./components/ouroffers";
import Reviews from "./components/reviews";
import HowItWorks from "./components/howtodo";
import Clients from "./components/clients";
import Footer from "./components/footer";
import ContactForm from "./components/questions";
import FaqsSection from "./components/faqsection";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the duration as needed (in milliseconds)
  }, []);

  return (
    <div className="">
      {isLoading ? (
        <div className="h-screen w-screen bg-[#1F1F29] flex flex-col items-center justify-center">
          <div className="focus-in-expand -mt-28">
            <img src="/assets/orglogo.png" width={900} alt="AEKIT" />
          </div>
          <div className="focus-in-expand  wrapper flex lg:-mt-16">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      ) : (
        <div className="intro bg-white">
          {/* <Intro /> */}
          <ContactForm />
          <AboutHE />
          <Clients />
          <HowItWorks />
          <OurOffers />
          <Reviews />
          <FaqsSection />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
